import React from "react"
import styles from "./partner-header.module.scss"
import Text5 from "../text/text-5"

const PartnerHeader = ({ title, mediaPath }) => {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.pattern} />
      <div className={styles.content}>
        <img className={styles.image} src={mediaPath} alt="" />
        <div className={styles.spacer} />
        <Text5 text={title} />
      </div>
    </div>
  )
}

export default PartnerHeader
