import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import Layout from "../components/layout/layout"
import RichTextField from "../components/textfield/rich"
import PageTransition from "../components/transition/page"
import styles from "./partner.module.scss"
import PartnerHeader from "../components/layout/partner-header"
import SEO from "../components/seo/seo"

class PartnerTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }

  animate() {
    this.pageTransition.current.play()
  }

  render() {
    const locale = this.props.pageContext.locale
    const { partner } = this.props.data
    return (
      <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={partner.seo.title}
          description={partner.seo.description}
          keywords={partner.seo.keywords && partner.seo.keywords.keywords}
          image={partner.seo.image.file.url}
        />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <PartnerHeader title={partner.name} mediaPath={partner.logoWhite && partner.logoWhite.file.url} />
            </div>
            <div className={styles.richText}>
              <RichTextField json={partner.body.json} locale={locale} />
            </div>
            <div className={styles.socialItems}>
              {partner.websiteUrl ? <a className={styles.iconContainer} href={partner.websiteUrl} target="_blank" rel="noopener noreferrer"><img className={styles.icon} src={require('../images/website.svg')} alt=""/></a> : null}
              {partner.twitterUrl ? <a className={styles.iconContainer} href={partner.twitterUrl} target="_blank" rel="noopener noreferrer"><img className={styles.icon} src={require('../images/twitter.svg')} alt=""/></a> : null}
              {partner.youtubeUrl ? <a className={styles.iconContainer} href={partner.youtubeUrl} target="_blank" rel="noopener noreferrer"><img className={styles.icon} src={require('../images/youtube.svg')} alt=""/></a> : null}
              {partner.facebookUrl ? <a className={styles.iconContainer} href={partner.facebookUrl} target="_blank" rel="noopener noreferrer"><img className={styles.icon} src={require('../images/facebook.svg')} alt=""/></a> : null}
              {partner.instagramUrl ? <a className={styles.iconContainer} href={partner.instagramUrl} target="_blank" rel="noopener noreferrer"><img className={styles.icon} src={require('../images/instagram.svg')} alt=""/></a> : null}
            </div>
          </div>
        </div>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query PartnerTemplateQuery($slug: String!, $locale: String!) {
    partner: contentfulPartner(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      name
      facebookUrl,
      instagramUrl,
      twitterUrl,
      youtubeUrl,
      websiteUrl,
      seo {
        title
        description
        keywords {
          keywords
        }
        image {
          file {
            url
          }
        }
      }
      logoWhite {
        file {
          url
        }
      }
      image {
        file {
          url
        }
      }
      body {
        json
      }
    }
  }
`

export default PartnerTemplate
